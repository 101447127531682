@font-face {
  font-family: 'Poppins';  
  src: local('Poppins'), url(./assets/fonts/poppins/Poppins-Regular.ttf) format("truetype");
}

::-webkit-scrollbar {
  width: 0px;
}

p {
  font-family: 'Poppins';
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}